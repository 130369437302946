import LineGradient from '../components/LineGradient'
import { motion } from 'framer-motion'

const MyAwards = () => {
    return (
        <section id="awards" className="pt-10 pb-24">
            {/* Header and image section */}
            <div className="md:flex md:justify-between md:gap-16 mt-32">
                <motion.div
                    className="md:w-1/3"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >
                    <p className="font-playfair font-semibold text-4xl mb-5">
                        MY <span className="text-red">AWARDS</span>
                    </p>
                    <LineGradient width="w-2/5" />
                    <p className="mt-10">
                        I'm proud to have participated in the following
                        competitions.
                    </p>
                </motion.div>
            </div>

            {/* Awards section */}
            <div className="md:flex md:justify-between mt-16 gap-32">
                {/* Machine Learning */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32 mb-5">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">
                                01
                            </p>
                            <p className="font-playfair font-semibold text-3xl mt-3">
                                Machine Learning
                            </p>
                        </div>
                    </div>
                    <div className="w-1/2 md:w-3/4">
                        <div className="h-32 bg-blue abolute right-0 top-0 z-[-1]" />
                        <ul className="list-none mt-5 space-y-4">
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-red rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                1st place in the Danish National Championship in
                                AI for university students 2024.
                            </li>
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-yellow rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                2nd place twice in the Danish National
                                Championship in AI for university students 2022
                                and 2023.
                            </li>
                        </ul>
                    </div>
                </motion.div>

                {/* Algorithms */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32  mb-5">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">
                                02
                            </p>
                            <p className="font-playfair font-semibold text-3xl mt-3">
                                Algorithms
                            </p>
                        </div>
                    </div>
                    <div className="w-1/2 md:w-3/4">
                        <div className="h-32 bg-red abolute right-0 top-0 z-[-1]" />
                        <ul className="list-none mt-5 space-y-4">
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-yellow rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                2nd place in the Danish National Programming
                                Competition for university students 2021.
                            </li>
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-blue rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                Two times winner of the Danish National Olympiad
                                in Informatics (Dansk Datalogi Dyst, Dyst 2)
                                2019 and 2020.
                            </li>
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-red rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                Bronze medal in the Nordic Olympiad in
                                Informatics for high school students 2020.
                            </li>
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-yellow rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                Represented the University of Copenhagen in the
                                ICPC Northwestern Europe Regional Contest every
                                year from 2020 to 2023.
                            </li>
                        </ul>
                    </div>
                </motion.div>

                {/* Cyber Security */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32 mb-5">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">
                                03
                            </p>
                            <p className="font-playfair font-semibold text-3xl mt-3">
                                Cyber Security
                            </p>
                        </div>
                    </div>
                    <div className="w-1/2 md:w-3/4">
                        <div className="h-32 bg-yellow abolute right-0 top-0 z-[-1]" />
                        <ul className="list-none mt-5 space-y-4">
                            <li className="flex items-center">
                                <span
                                    className="w-3 h-3 bg-blue rounded-full inline-block 
                                mr-3 flex-shrink-0 self-center"
                                ></span>
                                Finalist in the Danish National Championship in
                                Cyber Security 2021.
                            </li>
                        </ul>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

export default MyAwards
